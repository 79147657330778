module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":true,"maxWidth":1200,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Institut für Personalisierte Kosmetik COSMETIESE, Inhaberin Dr. med. Anna Maria Müller","short_name":"COSMETIESE","description":"CCOSMETIESE: Institut für Personalisierte Kosmetik, spezialisiert auf Produkte und Behandlungen der personalisierten High-End-Hautpflege von Biologique Recherche, Inhaberin Dr. med. Anna Maria Müller.","start_url":"/","lang":"de","display":"standalone","icon":"static/favicons/favicon.png","background_color":"#666","theme_color":"#fff","icons":[{"src":"static/favicons/favicon_512px.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_256px.png","sizes":"256x256","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_192px.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_127px.png","sizes":"127x127","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_96px.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_48px.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_192_mascable.png","sizes":"192x192","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"966878d77848401bc872797c139bc422"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebote-js": () => import("./../../../src/pages/angebote.js" /* webpackChunkName: "component---src-pages-angebote-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-produkte-js": () => import("./../../../src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-specials-js": () => import("./../../../src/pages/specials.js" /* webpackChunkName: "component---src-pages-specials-js" */),
  "component---src-pages-ueber-js": () => import("./../../../src/pages/ueber.js" /* webpackChunkName: "component---src-pages-ueber-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js-content-file-path-content-blog-2024-09-03-gesichtsbehandlungen-in-bremen-nach-methode-biologieque-recherche-index-mdx": () => import("./../../../src/templates/blog-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/blog/2024-09-03-Gesichtsbehandlungen-in-Bremen-nach-Methode-Biologieque-Recherche/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-content-blog-2024-09-03-gesichtsbehandlungen-in-bremen-nach-methode-biologieque-recherche-index-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-content-blog-2024-11-10-beauty-blog-index-mdx": () => import("./../../../src/templates/blog-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/blog/2024-11-10-BeautyBlog/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-content-blog-2024-11-10-beauty-blog-index-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-content-blog-2024-11-23-neue-innovation-von-biologique-recherche-progeskin-serum-index-mdx": () => import("./../../../src/templates/blog-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/blog/2024-11-23-neue-Innovation-von-biologique-recherche-Progeskin-Serum/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-content-blog-2024-11-23-neue-innovation-von-biologique-recherche-progeskin-serum-index-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-content-blog-2024-12-06-kosmetische-behandlungen-bei-cosmetiese-index-mdx": () => import("./../../../src/templates/blog-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/blog/2024-12-06-KosmetischeBehandlungenBeiCosmetiese/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-content-blog-2024-12-06-kosmetische-behandlungen-bei-cosmetiese-index-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-content-blog-2024-12-09-kosmetische-gesichtsbehandlungen-index-mdx": () => import("./../../../src/templates/blog-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/blog/2024-12-09-KosmetischeGesichtsbehandlungen/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-content-blog-2024-12-09-kosmetische-gesichtsbehandlungen-index-mdx" */),
  "component---src-templates-blog-template-js-content-file-path-content-blog-2024-12-12-kosmetisches-microneedling-in-bremen-index-mdx": () => import("./../../../src/templates/blog-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/blog/2024-12-12-kosmetisches-microneedling-in-bremen/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-js-content-file-path-content-blog-2024-12-12-kosmetisches-microneedling-in-bremen-index-mdx" */),
  "component---src-templates-news-list-template-js": () => import("./../../../src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-news-template-js-content-file-path-content-news-2024-08-01-biologique-recherche-jetzt-in-bremen-index-mdx": () => import("./../../../src/templates/news-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/news/2024-08-01-BiologiqueRecherche_jetzt_in_bremen/index.mdx" /* webpackChunkName: "component---src-templates-news-template-js-content-file-path-content-news-2024-08-01-biologique-recherche-jetzt-in-bremen-index-mdx" */),
  "component---src-templates-news-template-js-content-file-path-content-news-2024-10-15-biologique-recherche-scalp-hair-in-bremen-index-mdx": () => import("./../../../src/templates/news-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/news/2024-10-15-BiologiqueRecherche_ScalpHair_in_bremen/index.mdx" /* webpackChunkName: "component---src-templates-news-template-js-content-file-path-content-news-2024-10-15-biologique-recherche-scalp-hair-in-bremen-index-mdx" */),
  "component---src-templates-news-template-js-content-file-path-content-news-2024-11-17-neue-homepage-index-mdx": () => import("./../../../src/templates/news-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/news/2024-11-17-Neue-Homepage/index.mdx" /* webpackChunkName: "component---src-templates-news-template-js-content-file-path-content-news-2024-11-17-neue-homepage-index-mdx" */),
  "component---src-templates-product-list-template-js": () => import("./../../../src/templates/product-list-template.js" /* webpackChunkName: "component---src-templates-product-list-template-js" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-bio-kiss-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/BioKiss/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-bio-kiss-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-adn-metamorphique-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeADNMetamorphique/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-adn-metamorphique-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-aux-acides-de-fruits-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeAuxAcidesDeFruits/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-aux-acides-de-fruits-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-dermo-rl-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeDermoRl/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-dermo-rl-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-dermopurifiante-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeDermopurifiante/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-dermopurifiante-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-elastine-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeElastine/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-elastine-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-grand-millesime-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeGrandMillesime/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-grand-millesime-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-hydravit-s-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeHydravitS/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-hydravit-s-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-iso-placenta-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeIsoPlacenta/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-iso-placenta-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-masque-vernix-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeMasqueVernix/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-masque-vernix-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-splendide-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeSplendide/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-splendide-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-verte-espoir-ar-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeVerteEspoirAr/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-verte-espoir-ar-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-vipo-2-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/CremeVIPO2/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-creme-vipo-2-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-lift-kiss-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/LiftKiss/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-lift-kiss-index-mdx" */),
  "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-serum-yall-o-2-index-mdx": () => import("./../../../src/templates/product-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/produkte/gesicht/Serum-YallO2/index.mdx" /* webpackChunkName: "component---src-templates-product-template-js-content-file-path-content-produkte-gesicht-serum-yall-o-2-index-mdx" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-tip-list-template-js": () => import("./../../../src/templates/tip-list-template.js" /* webpackChunkName: "component---src-templates-tip-list-template-js" */),
  "component---src-templates-tip-template-js-content-file-path-content-tips-2024-10-10-beauty-tips-zum-herbst-index-mdx": () => import("./../../../src/templates/tip-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/tips/2024-10-10-BeautyTipsZumHerbst/index.mdx" /* webpackChunkName: "component---src-templates-tip-template-js-content-file-path-content-tips-2024-10-10-beauty-tips-zum-herbst-index-mdx" */),
  "component---src-templates-tip-template-js-content-file-path-content-tips-2024-12-05-w-interpflege-der-haut-index-mdx": () => import("./../../../src/templates/tip-template.js?__contentFilePath=C:/code/gatsby5/cosmetiese2.0/content/tips/2024-12-05-WInterpflege-der-Haut/index.mdx" /* webpackChunkName: "component---src-templates-tip-template-js-content-file-path-content-tips-2024-12-05-w-interpflege-der-haut-index-mdx" */)
}

